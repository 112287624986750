import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const WorkItem = ({ children, className = '', slug }) => (
  <li
    className={`bb ${className} relative pointer hover-bg-light-gray flex-auto flex flex-column justify-end items-start`}
  >
    <Link className="pa4 pb3 absolute absolute--fill" to={slug}>
      {children}
    </Link>
  </li>
)

WorkItem.propTypes = {
  className: PropTypes.string,
}

WorkItem.defaultProps = {
  className: '',
}

export default WorkItem
