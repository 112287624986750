import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Text from '../components/Text'
import WorkItem from '../components/WorkItem'
import PageWrapper from '../components/PageWrapper'
import PageTitle from '../components/PageTitle'

const ImgWrapper = styled.div`
  flex: 0 1 auto;
  border: solid 1px var(--primeColor);
  line-height: 0;
  padding: 1rem;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  & > .gatsby-image-wrapper {
    border: solid 5px var(--primeColor);
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: solid 10px var(--primeColor);
      mix-blend-mode: var(--bgMix);
    }
  }
  &:after,
  &:before {
    border-color: var(--primeColor);
    border-style: solid;
    position: absolute;
    content: '';
  }
  &:before {
    height: 80%;
    width: 80%;
    border-width: 1px;
  }
  &:after {
    height: 95%;
    width: 95%;
    border-width: 5px;
  }
`

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const image = data.image

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="About"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <PageWrapper>
          <Text>
            <PageTitle>About</PageTitle>
            <p>
              Miles Elliott is a designer and developer based in Raleigh, N.C.
            </p>
            <p>
            Miles is a developer and designer who strives to build creative, reliable, and successful solutions for problems big and small.
            </p>
            <p>
            His focus is on UI Engineering with React and WordPress, but he has a depth of experience all around the WordPress stack, the React ecosystem, and beyond. Miles has experience with Express, Preact, Svelte, Gatsby, Next, GraphQL, SVG, and a solid foundation of vanilla HTML, CSS, and JavaScript 
            </p>
          </Text>
          <ImgWrapper>
            <Img fixed={image.childImageSharp.fixed} />
          </ImgWrapper>
        </PageWrapper>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    image: file(absolutePath: { regex: "/miles_elliott.jpg/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
